<template>
  <v-container fluid>
    <!-- Error Dialog -->
    <v-row v-show="videoError" @click="closeError()">
      <v-col offset="3" cols="6">
        <v-fade-transition>
          <v-alert dense type="error" v-if="videoError">
          {{videoError}}
          </v-alert>
        </v-fade-transition>
      </v-col>
    </v-row>
    <!-- MAIN -->
    <v-row v-if="videoId" class="mt-0 pt-0">
      <v-col cols="7">
        <!-- Video Player -->
        <v-card elevation="0" v-if="videoId" ref="player-container">
          <v-row no-gutters>
            <v-col
              v-if="syncState === 0 || syncState === 1"
              :cols="syncState === 0 ? 6 : 12">
              <v-btn
                v-if="syncState === 0"
                outlined text block class="mr-1 mb-2"
                @click="syncState = 1"
              >USE</v-btn>
              <youtube
                :video-id="videoId"
                player-width="100%"
                :player-height="videoHeight"
                @ready="videoReady"
              ></youtube>
            </v-col>
            <v-col
              v-if="syncState === 0 || syncState === 2"
              :cols="syncState === 0 ? 6 : 12">
              <v-btn
                v-if="syncState === 0"
                outlined text block class="mr-1 mb-2"
                @click="syncState = 2"
              >USE</v-btn>
              <youtube
                :video-id="videoId"
                player-width="100%"
                :player-height="videoHeight"
                @ready="videoReady"
              ></youtube>
            </v-col>
          </v-row>
        </v-card>
        <!-- Media Controls -->
        <v-card class="pa-1" elevation="0" v-show="syncState !== 0">
          <v-btn outlined class="mr-2" @click="syncState = 0">
            <v-icon left>mdi-sync</v-icon> Sync
          </v-btn>
          <v-btn v-if="!videoStart && !infoLoading" outlined class="mr-2" @click="recheckInfo()">
            <v-icon left>mdi-help</v-icon> Recheck Time
          </v-btn>
          <!-- <v-btn outlined class="mr-2">
            <v-icon left>mdi-arrow-all</v-icon> Resize
          </v-btn> -->
          <!-- <v-btn outlined class="mr-2" v-if="!currentStamp">
            <v-icon left>mdi-information-outline</v-icon> Timer
          </v-btn> -->
          <div class="float-right text-right font-weight-bold red--text text-h5">
            {{currentStamp || '00:00:00'}}
          </div>
        </v-card>
        <!-- Custom Timer -->
        <v-card class="pa-1 mb-4" elevation="0" v-show="syncState !== 0">
          <v-btn outlined class="mr-2" @click="timerStart()">
            <v-icon left>mdi-play</v-icon> Start
          </v-btn>
          <v-btn outlined class="mr-2" @click="timerReset()">
            <v-icon left>mdi-refresh</v-icon> Reset
          </v-btn>
          <div class="float-right text-right font-weight-bold blue--text text-h5">
            {{customStamp || '00:00:00'}}
          </div>
        </v-card>
        <!-- Stream Sync -->
        <v-card class="pa-1 mb-4" elevation="0" v-show="syncState === 0">
          <h3 class="mb-2">Stream Syncing</h3>
          <ul class="mb-4">
            <li>
              <strong>Why is this important?</strong>
              Getting a video feed with least amount of latency is very important.
              If you're getting a late video feed, all your timestamps will be offset.
              When you post it on the archive, clicking the stamps will not seek
              directly to the desired moment.
            </li>
            <li>
              <strong>How does this work?</strong>
              This opens a new player, so you can re-attempt to get a video feed with lower latency.
              Click on the "Use" button above the video with lower latency.
              Basically "Use" the video where things happen earlier.
            </li>
            <li>
              <strong>PROTIP:</strong>
              Pausing a video for 5~10 seconds will help it catch up first.
              Resuming playback afterwards usually yields a lower latency video feed.
            </li>
            <li>
              However, even with pause technique,
              its still advised to attempt sync 2~3 more times
              to make sure you're really getting best video feed.
            </li>
            <li>
              If it's difficult to find out the better video feed using visuals,
              you can change the volume of the other stream to differentiate by audio.
            </li>
            <li>
              If you're timestamping a completed stream which is already archived,
              you do not need this whole process.
            </li>
            <li>
              When the livestream is complete, you can also use this sync feature
              to get a player that reloads the "archive" video.
            </li>
          </ul>
          <h3 class="mb-2">About the Timers</h3>
          <ul>
            <li>
              <strong>What is the <span class="blue--text">BLUE timer</span> for?</strong>
              You'd want to start custom timer the moment the stream starts for you.
              This is when the archived video will start at 00:00
              and will serve as basis for making your stamps.
            </li>
            <li>
              However, it is still recommended to use [Sync] feature to get the latest feed.
              If the stream lags, either on streamer side or stamper side,
              its not possible to get back the same timings as before.
              However, if we know we're getting the latest feed, even if stream dies,
              we can be confident we can get back to the same timings using sync.
            </li>
            <li>
              If you're confident to be getting the latest feed now,
              you'll only need to base on the
              <span class="red--text font-weight-bold">RED Timer</span>.
              It is automatically taken from HoloDex API, which uses real YouTube start time.
            </li>
            <li>
              If red timer does not show up or says "00:00:00",
              HoloDex has not yet detected it to start.
              This usually happens on the first few minutes when the stream starts.
              Click on <strong>Recheck Time</strong> button to get info from HoloDex again.
            </li>
            <li>
              Above scenario is probably the only time where blue timer is useful.
              Since the red timer is not available for the first few minutes,
              you may need to use blue timer for first few minutes of stamps.
            </li>
            <li class="font-weight-bold">
              Ignore all the timers if you're stamping an archive.
              Use the timer on the YouTube player instead.
            </li>
          </ul>
        </v-card>
      </v-col>
      <v-col cols="5">
        <!-- Tab Navigation -->
        <v-tabs v-model="tab" grow height="36" class="mb-4">
          <v-tabs-slider></v-tabs-slider>
          <v-tab href="#tab-source">
            <v-icon left>mdi-format-list-numbered</v-icon> Source
          </v-tab>
          <v-tab href="#tab-visual" disabled>
            <v-icon left>mdi-file-tree-outline</v-icon> Visual
          </v-tab>
          <v-tab href="#tab-emoji">
            <v-icon left>mdi-emoticon-happy-outline</v-icon> Emoji
          </v-tab>
          <v-tab href="#tab-chat">
            <v-icon left>mdi-chat-processing-outline</v-icon> Chat
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <!-- Tab: Source -->
          <v-tab-item value="tab-source">
            <v-card class="pa-1 mb-2" outlined>
              <v-btn small outlined text class="mr-1" @click="addStamp(-20)">-20</v-btn>
              <v-btn small outlined text class="mr-1" @click="addStamp(-15)">-15</v-btn>
              <v-btn small outlined text class="mr-1" @click="addStamp(-10)">-10</v-btn>
              <v-btn small outlined text class="mr-1" @click="addStamp(-5)">-5</v-btn>
              <v-btn small outlined text class="mr-1" @click="addStamp(-3)">-3</v-btn>
              <v-btn small outlined text class="mr-1" @click="addStamp()">0</v-btn>
            </v-card>
            <v-card class="pa-1" elevation="0">
              <v-textarea label="Timestamps" ref="worksource" v-model="work" rows="20" outlined>
              </v-textarea>
            </v-card>
          </v-tab-item>
          <!-- Tab: Visual -->
          <v-tab-item value="tab-visual">
            2
          </v-tab-item>
          <!-- Tab: Emojis -->
          <v-tab-item value="tab-emoji" class="emojis">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center" style="width:60px;">
                      Emoji
                    </th>
                    <th class="text-left">
                      Purpose
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-center">❤️</td>
                    <td>For use during precious / seiso moments</td>
                  </tr>
                  <tr>
                    <td class="text-center">📘</td>
                    <td>When they tell stories, memories, etc</td>
                  </tr>
                  <tr>
                    <td class="text-center">🎶</td>
                    <td>When singing songs. Tag only on longer renditions.</td>
                  </tr>
                  <tr>
                    <td class="text-center">☠️</td>
                    <td>When they die on a game, or a Game Over screen</td>
                  </tr>
                  <tr>
                    <td class="text-center">😱</td>
                    <td>When they scream in fear, usually in horror games</td>
                  </tr>
                  <tr>
                    <td class="text-center">😨</td>
                    <td>Scared moments, but not screaming</td>
                  </tr>
                  <tr>
                    <td class="text-center">🎉</td>
                    <td>Celebration, e.g. when they win in a game</td>
                  </tr>
                  <tr>
                    <td class="text-center">🌊</td>
                    <td>For bathroom breaks, etc</td>
                  </tr>
                  <tr>
                    <td class="text-center">🔞</td>
                    <td>Anything NSFW</td>
                  </tr>
                  <tr>
                    <td class="text-center">📜</td>
                    <td>A chapter of a game's story, section of a content, etc.</td>
                  </tr>
                  <tr>
                    <td class="text-center">├</td>
                    <td>A timestamp entry</td>
                  </tr>
                  <tr>
                    <td class="text-center">└</td>
                    <td>Last timestamp entry in a section</td>
                  </tr>
                  <tr>
                    <td class="text-center font-weight-bold">Bold</td>
                    <td>*Enclose with asterisk*</td>
                  </tr>
                  <tr>
                    <td class="text-center font-italic">Italics</td>
                    <td>_Enclose with underscore_</td>
                  </tr>
                  <tr>
                    <td class="text-center text-decoration-line-through">Strike</td>
                    <td>-Enclose with hyphens-</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-tab-item>
          <!-- Tab: Chat -->
          <v-tab-item value="tab-chat">
            <iframe
              v-if="videoId && tab === 'tab-chat'"
              width="100%"
              height="500"
              :src="`https://www.youtube.com/live_chat?v=${videoId}&embed_domain=${host}`"
              frameborder="0">
            </iframe>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';
import { DateTime, Duration } from 'luxon';

export default {
  data: () => ({
    host: window.location.host,
    tab: 'tab-source',
    player: null,
    videoHeight: 320,
    syncState: 1,
    currentStamp: '',
    customTimer: null,
    customStamp: '',
    infoLoading: false,
    work: `🕑 Timestamps
0:00 Start
0:00 Disclaimers

📜 0:00 *Section_Name*
├ 0:00 Moment_1
    ├ 0:00 Moment_2
    └ Extra_Notes
├ 0:00 Moment_3
├ 0:00 Moment_4
└ 0:00 Moment_5

🌊 0:00:00 BREAK ~ 0:00:00

📜 0:00:00 *SuperChat/StreamLabs Readings*
├ 0:00:00 Tangent_Topic_1
├ 0:00:00 Tangent_Topic_2
├ 0:00:00 Tangent_Topic_3
├ 0:00:00 StreamLabs
├ 0:00:00 Tangent_Topic_4
└ 0:00:00 Tangent_Topic_5

0:00:00 Closing Remarks
`,
    lines: [],
  }),
  computed: {
    videoId() {
      return this.$store.state.videoId;
    },
    videoStart() {
      return this.$store.state.videoStart;
    },
    videoError() {
      return this.$store.state.videoError;
    },
  },
  watch: {
    syncState() {
      this.recalculateVideoHeight();
    },
    videoId() {
      this.$nextTick(() => {
        this.recalculateVideoHeight();
        this.autoLoad();
      });
    },
  },
  methods: {
    videoReady(event) {
      this.player = event.target;
      this.player.playVideo();
    },
    recalculateVideoHeight() {
      const width = this.$refs['player-container'].$el.clientWidth;
      if (this.syncState) {
        this.videoHeight = (width / 16) * 9;
      } else {
        this.videoHeight = (width / 32) * 9;
      }
    },
    tick() {
      // Real video stamp
      if (this.videoStart) {
        const secDiff = DateTime.now().diff(DateTime.fromISO(this.videoStart), 'seconds');
        this.currentStamp = Duration.fromObject({ seconds: secDiff.toObject().seconds }).toFormat('hh:mm:ss');
      } else {
        this.currentStamp = '';
      }
      // Custom timer
      if (this.customTimer) {
        const secDiff = DateTime.now().diff(DateTime.fromMillis(this.customTimer), 'seconds');
        this.customStamp = Duration.fromObject({ seconds: secDiff.toObject().seconds }).toFormat('hh:mm:ss');
      }
    },
    timerStart() {
      this.customTimer = DateTime.now().toMillis();
    },
    timerStop() {
      this.customTimer = null;
    },
    timerReset() {
      this.customTimer = null;
      this.customStamp = '00:00:00';
    },
    async recheckInfo() {
      this.infoLoading = true;
      // Fetch video information from HoloDex
      const videoInfo = await this.getVideoInfo(this.videoId).catch(() => null);
      if (!videoInfo) {
        this.$nextTick(() => {
          this.$store.commit('videoError', 'Unable to get video information. The video must be supported by HoloDex.');
        });
        return;
      }
      // Store video information
      if (videoInfo.start_actual) this.$store.commit('videoStart', videoInfo.start_actual);
      this.infoLoading = false;
    },
    async getVideoInfo(videoId) {
      const info = await axios.get(`https://holodex.net/api/v2/videos/${videoId}`);
      return info.data;
    },
    closeError() {
      this.$store.commit('videoError', null);
    },
    addStamp(offset = 0) {
      const textArea = this.$refs.worksource.$el.querySelector('textarea');
      const selStart = textArea.selectionStart;
      if (!selStart) return;
      // Calculate where to insert stamp
      const prevLine = this.work.substring(0, selStart).lastIndexOf('\n');
      const nextLine = selStart + this.work.substring(selStart, this.work.length - 1).indexOf('\n');
      const selLine = this.work.substring(prevLine, nextLine);
      const numSpaces = selLine.split(/\S/g)[0].length - 1;
      const spaceString = [...new Array(numSpaces)].reduce((c) => `${c} `, '');
      const part1 = this.work.substring(0, nextLine);
      const part2 = this.work.substring(nextLine, this.work.length - 1);
      // Calculate timestamp number
      const secDiff = DateTime.now().plus({ seconds: offset }).diff(DateTime.fromISO(this.videoStart), 'seconds');
      const format = secDiff.toObject().seconds >= 3600 ? 'h:mm:ss' : 'm:ss';
      const stamp = Duration.fromObject({ seconds: secDiff.toObject().seconds }).toFormat(format);
      // Update the textarea
      const part1b = `${part1}\n${spaceString}├ ${stamp} `;
      this.work = `${part1b}${part2}`;
      this.$nextTick(() => {
        textArea.focus();
        textArea.selectionEnd = part1b.length;
      });
    },
    selectEmoji(event) {
      event.srcElement.select();
    },
    autoSave() {
      localStorage.setItem(this.videoId, this.work);
    },
    autoLoad() {
      if (localStorage.getItem(this.videoId)) {
        this.work = localStorage.getItem(this.videoId);
      }
    },
  },
  mounted() {
    setInterval(() => {
      this.tick();
    }, 250);
    setInterval(() => {
      this.autoSave();
    }, 5000);
  },
};
</script>

<style lang="scss">
textarea {
  font-family: monospace !important;
  font-size: 14px !important;
  line-height: 20px !important;
}
.emojis .v-text-field{
  width: 60px;
  input {
    text-align: center;
  }
}
</style>
