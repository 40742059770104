import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    videoId: null,
    videoStart: null,
    videoError: null,
  },
  mutations: {
    videoId(state, data) {
      state.videoId = data;
    },
    videoStart(state, data) {
      state.videoStart = data;
    },
    videoError(state, data) {
      state.videoError = data;
    },
  },
});
